import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./Table.module.scss";
import { Chip, TextField, FormControlLabel } from "@material-ui/core";
import { UUID } from "../../helpers/uuid";
import { getApiConfig } from "../../config/apiConfig";

const API_CONFIG = getApiConfig();

function desc(a, b, orderBy) {
  var aValue, bValue
  if (orderBy == "weekyear") {
    aValue = convertToDate(a[orderBy]);
    bValue = convertToDate(b[orderBy]);
  } else {
    aValue = a[orderBy];
    bValue = b[orderBy];
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function convertToDate(stringDate) {
  // Break down the incoming string to it's constituent parts with some clever manip.
  let day = stringDate.substring(0, stringDate.indexOf('/'));
  let month = (stringDate.substring(stringDate.indexOf('/') + 1, stringDate.lastIndexOf('/'))) - 1; // Adjusted -1 for month, actual date obj's are 0-based, but strings are human-readable.
  let year = stringDate.substring(stringDate.lastIndexOf('/') + 1);

  // Create new Date obj from above
  var d = new Date(year, month, day);

  return d;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function SimpleTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.isSelectable && (
          <TableCell padding="checkbox">
            <Checkbox
              disabled={props.isEditing !== undefined && !props.isEditing}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onClick={() => onSelectAllClick(numSelected === rowCount)}
            />
          </TableCell>
        )}
        {props.headers.map((row) => (
          <TableCell
            key={row.id}
            align={row.align}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    borderRadius: 0,
    boxShadow: "none",
    marginBottom: theme.spacing(2),
    backgroundColor: "transparent"
  },
  tableWrapper: {
    overflowX: "auto",
    paddingBottom: "90px"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 0,
    width: 1
  }
}));

export default function SimpleTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("userId");
  const [stateSelected, setStateSelected] = React.useState(
    props.selected || []
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(isChecked) {
    if (!isChecked) {
      let rows = props.rows.map((n) => n[props.dataId]);

      const newSelecteds = props.checkDefault
        ? rows.filter((n) => !props.checkDefault(n))
        : rows;

      props.handleSelection(newSelecteds);
      setStateSelected(newSelecteds);
      return;
    }

    props.handleSelection([]);
    setStateSelected([]);
  }

  function handleCheck(event, name) {
    const selected = props.selected != null ? props.selected : stateSelected;
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    props.handleSelection(newSelected);
    setStateSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  // Helper function for deriving the year of week from a string structured like so: '20/12/2020/'
  // Why? TL;DR: Hiding fields in this table is a nightmare so we manipulate strings into-and-out-of date formats a lot.
  function getWeekNumber(d) {
    // Break down the incoming string to it's constituent parts with some clever manip.
    let day = d.substring(0, d.indexOf('/'));
    let month = (d.substring(d.indexOf('/') + 1, d.lastIndexOf('/'))) - 1; // Adjusted -1 for month, actual date obj's are 0-based, but strings are human-readable.
    let year = d.substring(d.lastIndexOf('/') + 1);

    // Create new Date obj from above
    d = new Date(year, month, day);

    // // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // // Set to nearest Thursday: current date + 4 - current day number
    // // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));

    // // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
  }

  const getRosterDownload = (weekyear, merchId) => {
    // Call helper to convert incoming date string into the format we need.
    let resp = getWeekNumber(weekyear);

    let week = resp[1];
    let year = resp[0];

    let url =
      process.env.NODE_ENV !== "production"
        ? `http://localhost:9005/api/GetRoster?Week=${week}&Year=${year}&Id=${merchId}`
        : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/Roster/GetRoster?Week=${week}&Year=${year}&Id=${merchId}`;
    window.open(url, "_blank");
  };

  function createRowItem(rowData, type, onChange) {
    if (type === "tag") {
      return <Chip label={String(rowData)} className={classes.chip} />;
    } else if (type === "sequence") {
      return (
        <TextField
          className={styles.truncate}
          disabled={!props.isEditing}
          value={rowData}
          onChange={(event) => onChange(event.target.value)}
          margin="dense"
          variant="outlined"
        />
      );
    } else if (type === "checkbox") {
      return (
        <FormControlLabel
          control={
            <Checkbox
              disabled={!props.isEditing}
              checked={rowData}
              onChange={(event) => onChange(event.target.checked)}
              value="checkedB"
              color="primary"
            />
          }
          label="Is Category"
        />
      );
    } else {
      return rowData;
    }
  }

  const isSelected = (name) =>
    (props.selected != null ? props.selected : stateSelected).indexOf(name) !==
    -1;

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={styles.table} aria-labelledby="tableTitle">
          <SimpleTableHead
            classes={classes}
            numSelected={
              (props.selected != null ? props.selected : stateSelected).length
            }
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
            headers={props.headers}
            isSelectable={props.isSelectable}
            isEditing={props.isEditing}
          />
          <TableBody>
            {stableSort(props.rows, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row[props.dataId]);
                const labelId = `enhanced-table-checkbox-${index}`;
                if (row.weekCommencing != null) {
                  return (
                    <TableRow
                      className={styles.tableRow}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {props.cells.map((cell) => {
                        return (
                          <TableCell key={UUID()} align={cell.align}>
                            <span
                              onClick={(event) => {
                                getRosterDownload(
                                  row.weekCommencing,
                                  row.merchandiserId);
                              }}
                              className={cell.clickable ? styles.rowName : ""}
                            >
                              {createRowItem(
                                row[cell.id] != null
                                  ? row[cell.id]
                                  : row[cell.secondaryId],
                                cell.type,
                                (data) => cell.onChange(data, row)
                              )}
                            </span>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow
                      className={styles.tableRow}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {props.isSelectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={
                              (props.isEditing !== undefined &&
                                !props.isEditing) ||
                              (props.checkDefault
                                ? props.checkDefault(row[props.dataId])
                                : false)
                            }
                            onClick={(event) =>
                              handleCheck(event, row[props.dataId])
                            }
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                      )}
                      {props.cells.map((cell) => {
                        return (
                          <TableCell key={UUID()} align={cell.align}>
                            <span
                              onClick={(event) => {
                                if (cell.clickable) cell.handleClick(event, row);
                              }}
                              className={cell.clickable ? styles.rowName : ""}
                            >
                              {createRowItem(
                                row[cell.id] != null
                                  ? row[cell.id]
                                  : row[cell.secondaryId],
                                cell.type,
                                (data) => cell.onChange(data, row)
                              )}
                            </span>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        className={styles.tablePagination}
        rowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          "aria-label": "previous page"
        }}
        nextIconButtonProps={{
          "aria-label": "next page"
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
