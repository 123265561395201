// React
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";

// UI and Styling
import {
    Button,
    ButtonGroup,
    Grid,
} from "@material-ui/core";
import styles from "./RosterListPage.module.scss";

// Actions
import { getMerchandiserRosters } from "../../state/actions/RosterActions";
import { getMerchandiserId } from "../../state/actions/UserActions";

// Components
import ExportExcel from "../../components/export/ExportExcel";
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import { Loading } from "../../components/loading/Loading";
import { PageContainer } from "../../components/page-container/PageContainer";
import { PagePlaceholder } from "../../components/page-placeholder/PagePlaceholder";
import Search from "../../components/search/Search";
import SimpleTable from "../../components/table/Table";
import ScrollableTabs from "../../components/tabs/Tabs";

// Other
import { getIcon } from "../../icon/icon";
import { getRolesForLoggedInUser } from "../../state/actions/RoleActions";
import { UserRoles } from "../../state/constants/UserRoles";

const RosterListPage = (props) => {
    // Dispatcher
    const dispatch = useDispatch();

    // Page Interaction States
    const [loading, setLoading] = useState(false);
    const [originalData, setOriginalData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);

    // Locals
    const rosters = useSelector((state) => state.RosterReducer.Rosters);
    const user = useSelector((state) => state.UserReducer.loggedInUser);
    const merchData = useSelector((state) => state.UserReducer.merchData);
    const loggedInUserRoles = useSelector((state) => state.RoleReducer.loggedInUserRoles);
    let { MerchandiserId } = useParams();

    const {
        SAM_M_ADMIN,
        SAM_M_MANAGER,
        SAM_M_MERCH
    } = UserRoles;

    // Initial Load Trigger and outgoing call
    useEffect(() => {
        setLoading(true);

        let userEmail = null;
        if (user != null && user.userName != null) {
            console.log("Logged in user: " + user.userName);
            userEmail = user.userName.split('\\')[1];
        } else {
            console.log("Error: User is not logged in", user);
        }

        dispatch(getRolesForLoggedInUser((success) => {
            if (loggedInUserRoles.includes(SAM_M_ADMIN) || loggedInUserRoles.includes(SAM_M_MANAGER)) {
                console.log("Manager");
                // * User is a manager, we don't need to auth that they're able to see this merchandisers rosters

                var employeeNumber = MerchandiserId?.toUpperCase();
                if (employeeNumber === undefined)
                {
                    employeeNumber = "";
                }

                dispatch(
                    getMerchandiserRosters(employeeNumber, userEmail, (success) => {
                        console.log("Manager got merchandiser rosters");
                        setLoading(false);
                    }));
            }
            else {
                // Get the merchandiser ID from the route.
                dispatch(getMerchandiserId(userEmail, (success) => {
                    console.log("Merchandiser id collection was a " + (success ? "succcess" : "failure"));

                    var employeeNumber = MerchandiserId?.toUpperCase();
                    if (employeeNumber === undefined)
                    {
                        employeeNumber = "";
                    }

                    if (employeeNumber != null && merchData !== undefined && Array.isArray(merchData) && !merchData.some(e => e.EmployeeNumber === employeeNumber)) {
                        employeeNumber = "";

                        // * Merchandiser is not meant to be here.
                        console.log("Merchandiser was not to be here");
                        props.history.push("/rosters");
                        setLoading(false);
                    }

                    dispatch(
                        getMerchandiserRosters(employeeNumber, userEmail, (success) => {
                            console.log("Merchandiser is correct");
                            console.log("Got merchandiser roster data");
                            setLoading(false);
                        }));
                }))
            }
        }))



    }, []);

    // On alteration of allRosters
    useEffect(() => {
        if (rosters !== undefined) {
            const data = createData(rosters);

            // Setup default search params, etc.
            setOriginalData(data);
            setSearchedData(data);
            //setupData();

            // If loading, end loading.
            if (loading) {
                setLoading(false);
            }
        }
    }, [rosters])

    // Build mapped data entries.
    const createData = (allRosters) => {
        const rosterKeys = Object.keys(allRosters);

        return rosterKeys.map((rosterKey) => {
            const roster = allRosters[rosterKey];

            return {
                employeeNumber: roster.EmployeeNumber,
                merchandiserId: roster.MerchandiserId,
                weekCommencing: roster.WeekCommencing,
                weekEnding: roster.WeekEnding,
                //year: roster.Year,
                revision: roster.Revision,
                active: roster.IsActive === true ? "True" : "False"
            };
        });
    };


    // Helper method to convert into a real date from a w+y.
    const getDateFromWeek = (weekNumber, year) => {
        //examples
        //ex1 = week 20, year 2023: correct Date 14/5/2023
        //ex2 = week 44, year 2022: incorrect Date 4/11/2022

        var januaryFirst = new Date(year, 0, 1); //date 1/1/year
        var firstDayOfYear = januaryFirst.getDay(); // 0 to 6 from Sunday = 0
        //ex1 = Sunday 0
        //ex2 = Saturday 6

        // Calculate the number of days to the first Monday of the year
        var daysToSecondSunday = ((8 - firstDayOfYear) % 7) + 6;
        //ex1 = 8-0=8 then 8 % 7 + 6 = 7 days
        //ex2 = 8-6=2 then 2 % 7 + 6 = 8 days

        // Calculate the number of days to the requested week's Sunday
        // added -1 weeks to account for week number to roster dates descrepancy
        var daysToRequestedSunday = (weekNumber - 2) * 7 + daysToSecondSunday;
        //ex1 = (20 - 2) * 7 + 7 day = 133 days
        //ex2 = (44 - 2) * 7 + 8 days = 302 days

        // Calculate the date of the requested week's Sunday add +1 to allow for the 1st of Jan
        var sundayDate = new Date(year, 0, daysToRequestedSunday + 1);
        //ex1 = 14/05/2023
        //ex2 = 31/10/2022

        return sundayDate.getDate() + '/' + (sundayDate.getMonth() + 1) + '/' + sundayDate.getFullYear();
    }

    // Generate table headers, utilised in renderData()
    const createHeaders = () => {
        return [
            {
                id: "employeeNumber",
                clickable: true,
                // For future readers, if you're wondering how this table routes to the right
                // endpoint on click, check the Table.js file and ctrl+f for '.weekyear'.
                action: { path: "/rosters/{id}", identifier: "employeeNumber" },
                align: "left",
                label: "Emp. Number",
                options: {
                    sort: false,
                }
            },
            {
                id: "weekCommencing",
                align: "left",
                label: "Week Commencing",
                options: {
                    sort: false,
                }
            },
            {
                id: "weekEnding",
                align: "left",
                label: "Week Ending",
                options: {
                    sort: false,
                }
            },
            {
                id: "revision",
                clickable: false,
                align: "left",
                label: "Revision",
                options: {
                    sort: false,
                }
            }
        ];
    };

    // Generate table cell data, used by renderData()
    const createCells = (headers) => {
        let cells = [];

        headers.forEach((header) => {
            let cell = {};

            cell.id = header.id;

            if (header.hasOwnProperty("action")) {
                cell.handleClick = (event, row) => {
                    let path = header.action.path.replace(
                        "{id}",
                        row[header.action.identifier]
                    );
                    props.history.push(path);
                };
            }

            cell.align = header.align;
            cell.clickable = header.clickable;
            cell.mask = "Download";

            cells.push(cell);
        });

        return cells;
    };

    // Render Table Contnet
    const renderData = () => {
        const headers = createHeaders();
        const cells = createCells(headers);

        const data =
            searchedData.length === 0 ? (
                <PagePlaceholder
                    image={getIcon("licencse.svg")}
                    text="No Rosters found."
                />
            ) : (
                <SimpleTable
                    handleSelection={null}
                    isSelectable={false}
                    onClick={() => { }}
                    dataId={"rosterId"}
                    cells={cells}
                    headers={headers}
                    rows={searchedData}
                />
            );

        return data;
    };

    return (
        <div>
            <HeaderBlock
                title={"Rosters"}
                right={
                    <Grid container spacing={2} alignItems="center" justify="flex-end">
                        <Grid item>
                            <Search
                                searchField={["weekCommencing"]}
                                returnSearchMatches={(data) => {
                                    setSearchedData(data);
                                }}
                                data={originalData}
                                searchTitle="Search..."
                            />
                        </Grid>
                    </Grid>
                }
            />
            <PageContainer>{loading ? <Loading /> : renderData()}</PageContainer>
        </div>
    );
}

const hoc = withRouter(RosterListPage);

// EXPORT COMPONENT
export { hoc as RosterListPage };
