// React
import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// UI and Styling
import { Box, Button, ButtonGroup, Grid } from "@material-ui/core";
import styles from "./MigrationPage.module.scss";

// Actions
import {
    MerchandiserContractMassUpload
} from "../../state/actions/MigrationActions";
import { getLoggedInUserByEmail } from "../../state/actions/UserActions";


// Components
import { HeaderBlock } from "../../components/header-block/HeaderBlock";
import {PageContainer} from "../../components/page-container/PageContainer";
import { LoadingComponent } from "../../components/loading2/CircleLoader";

// Segments
import MigrationUploadSegment from "../../segments/content-upload-segments/MigrationUploadSegment";
import { UUID } from "../../helpers/uuid";

// API
import { getApiConfig } from "../../config/apiConfig";
const API_CONFIG = getApiConfig();
const templateUrl = process.env.NODE_ENV !== "production"
    ? `http://localhost:9001/api/GetAllMerchandiserContractsAsCSV`
    : `${API_CONFIG.URL}/${API_CONFIG.SAMM}/MerchandiserManager/GetAllMerchandiserContractsAsCSV`

// Moment
var moment = require('moment');
moment().format();

const MerchContractMigrationPage = (props) => {
    const loggedInUser = useSelector((state) => state.UserReducer.loggedInUser);

    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [csvData, setCsvData] = useState({});
    const [dataChanged, setDataChanged] = useState(false);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [targetsEdited, setTargetsEdited] = useState(false);
    const [formEdited, setFormEdited] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loggedInUserCallMade, setLoggedInUserCallMade] = useState(false);


    const dispatch = useDispatch();

    //Use Effects
    useEffect(() => {
        setLoading(true);
        if (!loggedInUser || Object.keys(loggedInUser).length === 0) {
            dispatch(
                getLoggedInUserByEmail(() => {
                    setLoggedInUserCallMade(true);
                })
            );
        } else {
            setLoggedInUserCallMade(true);
        }
    }, []);

    useEffect(() => {
        if (targetsEdited) {
            setSubmitEnabled(true);
        }
    }, [targetsEdited]);

    useEffect(() => {
        if (dataChanged) {
            setDataChanged(false);
        }
    }, [dataChanged]);

    const handleSubmit = async () => {
        setIsUploading(true);
        if (!submitEnabled) {
            return;
        }

        setSubmitEnabled(false);

        const data = createRequestBody();

        dispatch(
            MerchandiserContractMassUpload(
                data,
                (res) => {
                    if (res) {
                        props.history.push("/post-migration");
                    }
                }
            )
        );
    };

    const createRequestBody = () => {
        let toReturn = [];
        csvData.forEach(row => {
            const requestBody = {
                ...row,
                CreatedDate: moment().toISOString(),
                CreatedBy: loggedInUser.userName,
                ModifiedBy: loggedInUser.userName,
                ModifiedDate: moment().toISOString()
            };
            toReturn.push(requestBody);
        });
        return toReturn;
    };

    const renderSubmitAndCancelButtons = () => {
        return [
            <Button
                key={UUID()}
                variant="outlined"
                onClick={() => handleSubmit()}
                disabled={!submitEnabled}
            >
                Submit
          </Button>
        ];
    };

    return (
        <Box>
            <Fragment>

                {isUploading ? (
                    <>
                        <HeaderBlock
                            title={"Please Wait: Merchandiser Contract Data is being uploaded"}
                        />
                        <PageContainer>
                            <LoadingComponent></LoadingComponent>
                        </PageContainer>
                    </>
                ) : (
                        <>
                            <HeaderBlock
                                title={"Merchandiser Contract Data Mass Upload"}
                                right={
                                    renderSubmitAndCancelButtons() ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                            justify="flex-end"
                                        >
                                            <Grid item>
                                                <ButtonGroup>{renderSubmitAndCancelButtons()}</ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    ) : null
                                }
                            />

                            <PageContainer>
                                <div className={styles.contentGrid}>
                                    <Grid container spacing={3}>
                                        <Grid key={"migrationUploadSegment"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <MigrationUploadSegment
                                                csvData={csvData}
                                                setCsvData={setCsvData}
                                                setDataChanged={setDataChanged}
                                                title="Upload Merchandiser Contract Data"
                                                targetsEdited={targetsEdited}
                                                setTargetsEdited={setTargetsEdited}
                                                isEditing={isEditing}
                                                setFormEdited={setFormEdited}
                                                description="Please upload a CSV containing the Merchandiser Contract data that you would like uploaded to SaM-M"
                                                columns={["MerchandiserEmployeeNumber", "Day1Hours", "Day2Hours", "Day3Hours", "Day4Hours", "Day5Hours",
                                                    "Day6Hours", "Day7Hours", "EffectiveDate", "IsActive"]}
                                                templateFileName="Merchandiser_Template.csv"
                                                allDataUrl={templateUrl}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </PageContainer>
                        </>)}
            </Fragment>
        </Box>
    );
};

const hoc = withRouter(MerchContractMigrationPage);

// EXPORT COMPONENT
export { hoc as MerchContractMigrationPage };